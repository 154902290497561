.backgroundPresentation{
    background: #144662;
    height: 100vh;
    display: flex;
    align-items: center;
    align-self: center;
    color: white;
}
.logo{
    margin: 0 auto;
    display: flex;
    animation: myAnim 3s ease 0s infinite normal forwards;
    width: 20vh;
}

.emailHipervinculo{
    color:white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes myAnim {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

@media screen and (max-width: 600px) {
    .backgroundPresentation{
        height: auto;
    }
}
