.logoFlotanteWhatsapp{
    position: fixed;
    bottom: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 2;
    width: 80px;
    height: 80px;
}

@media (max-width: 800px) {
    .logoFlotanteWhatsapp{
        width: 50px;
        height: 50px;
    }
}